import { LoanTransactionModel } from "./loanTransaction";

export type LoanModel = {
  _id?: number;
  amount: number;
  yearly_interest: number;
  loan_date: string;
  borrower: string;
  desc: string;
  status: number;
  transactions: LoanTransactionModel[];
};

export const LoanModelStatus = {
  UNPAID: 0,
  PAID: 1,
};
