import * as React from "react";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import CustomDatePicker from "../../common/customDatePicker";
import { LoanModel, LoanModelStatus } from "../../../models/loan";
import { loanRemainingCalculator } from "../../../helpers/loanCalculator";
import LoanApi from "../../../helpers/api/loan";
import { parseInputNumber } from "../../../helpers/formatter";

type PropTypes = {
  loan: LoanModel;
  state: {
    show: {
      value: boolean;
      set: Function;
    };
  };
};

const NewPaymentModal: React.FC<PropTypes> = ({ state, loan }) => {
  const [inputAmount, setInputAmount] = useState(0);
  const [inputTransactionDate, setInputTransactionDate] = useState(new Date());
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const submitHandler = async () => {
    const loanData = { ...loan };
    setIsButtonLoading(true);

    loanData.transactions.push({
      amount: inputAmount,
      transaction_date: inputTransactionDate.toISOString(),
    });

    if (loanRemainingCalculator(loanData) <= 0) {
      loanData.status = LoanModelStatus.PAID;
    }

    await LoanApi.newPayment(loanData);
    window.location.reload();
  };

  return (
    <>
      <Modal
        show={state.show.value}
        fullscreen
        onHide={() => state.show.set(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Pembayaran</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Jumlah Pembayaran</Form.Label>
              <Form.Control
                type="number"
                placeholder="Jumlah Pembayaran"
                autoComplete="off"
                value={inputAmount.toString()}
                onChange={(e) => {
                  parseInputNumber(e.target.value, setInputAmount);
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Tangal Pinjaman</Form.Label>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                maxDate={new Date()}
                onChange={(e: Date) => setInputTransactionDate(e)}
                selected={inputTransactionDate}
                customInput={<CustomDatePicker />}
              />
            </Form.Group>

            <div className="d-grid gap-2 pb-2">
              <Button
                variant="primary"
                disabled={isButtonLoading}
                onClick={submitHandler}
              >
                {isButtonLoading ? "Loading..." : "Submit"}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NewPaymentModal;
