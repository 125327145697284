import { Button, Modal, Table } from "react-bootstrap";
import LoanDetail from "../loanDetail";
import * as moment from "moment";
import { currencyFormatter } from "../../../helpers/formatter";
import * as React from "react";
import { useState } from "react";
import useAuth from "../../../hooks/auth";
import NewPaymentModal from "./newPayment";
import { LoanModel } from "../../../models/loan";
import { loanRemainingCalculator } from "../../../helpers/loanCalculator";

type PropTypes = {
  loanDetail: LoanModel;
  state: {
    show: {
      value: boolean;
      set: Function;
    };
  };
};

const LoanDetailModal: React.FC<PropTypes> = ({ state, loanDetail }) => {
  const [showNewPayment, setShowNewPayment] = useState(false);

  const [auth] = useAuth();

  return (
    <>
      <Modal
        show={state.show.value}
        fullscreen
        onHide={() => state.show.set(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Pinjaman</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loanDetail && (
            <LoanDetail
              loan={loanDetail}
              // handleViewLoan={handleViewLoan}
              showButton={false}
            />
          )}

          {/*Show new payment*/}
          {auth.isAdmin && (
            <div className="d-grid gap-2 pb-2">
              <Button
                onClick={() => setShowNewPayment(true)}
                disabled={loanRemainingCalculator(loanDetail) <= 0}
              >
                Pembayaran baru
              </Button>
            </div>
          )}

          <h3>Riwayat Transaksi</h3>

          <div>
            <Table striped bordered hover>
              <tbody>
                {loanDetail.transactions.map((tx, i) => (
                  <tr key={i}>
                    <td width="50%">
                      {moment(tx.transaction_date).format("DD/MM/YYYY")}
                    </td>
                    <td className="text-end">
                      {currencyFormatter.format(tx.amount)}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td width="50%">Total Pembayaran</td>
                  <td className="text-end">
                    {currencyFormatter.format(
                      loanDetail.transactions.reduce(
                        (accumulator, tx) => accumulator + tx.amount,
                        0
                      ) || 0
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
      {showNewPayment && (
        <NewPaymentModal
          loan={loanDetail}
          state={{
            show: {
              value: showNewPayment,
              set: setShowNewPayment,
            },
          }}
        />
      )}
    </>
  );
};

export default LoanDetailModal;
