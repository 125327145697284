import * as React from "react";
import { useState } from "react";
import { Button, Form, FormControl, InputGroup, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { LoanModel, LoanModelStatus } from "../../../models/loan";
import CustomDatePicker from "../../common/customDatePicker";
import LoanApi from "../../../helpers/api/loan";
import { parseInputNumber } from "../../../helpers/formatter";

type PropTypes = {
  state: {
    show: {
      value: boolean;
      set: Function;
    };
  };
};

const NewLoanModal: React.FC<PropTypes> = ({ state }) => {
  const [inputBorrower, setInputBorrower] = useState("");
  const [inputLoanDesc, setInputLoanDesc] = useState("");
  const [inputAmount, setInputAmount] = useState(0);
  const [inputDate, setInputDate] = useState(new Date());
  const [inputInterest, setInputInterest] = useState(15);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const submitHandler = async () => {
    setIsButtonLoading(true);
    const loanData: LoanModel = {
      borrower: inputBorrower,
      desc: inputLoanDesc,
      amount: inputAmount,
      yearly_interest: inputInterest / 100,
      loan_date: inputDate.toISOString(),
      status: LoanModelStatus.UNPAID,
      transactions: [],
    };

    LoanApi.newLoan(loanData).then(() => {
      window.location.reload();
    });
  };

  return (
    <>
      <Modal
        show={state.show.value}
        fullscreen
        onHide={() => state.show.set(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Pinjaman Baru</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => {
                  setInputBorrower(e.currentTarget.value);
                }}
                value={inputBorrower}
              >
                <option>Peminjam</option>
                <option value="Daus">Daus</option>
                <option value="Endah">Endah</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Keperluan</Form.Label>
              <Form.Control
                type="text"
                placeholder="Keperluan"
                autoComplete="off"
                value={inputLoanDesc}
                onChange={(e) => {
                  setInputLoanDesc(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Jumlah Pinjaman</Form.Label>
              <Form.Control
                type="number"
                placeholder="Jumlah Pinjaman"
                autoComplete="off"
                value={inputAmount.toString()}
                onChange={(e) => {
                  parseInputNumber(e.target.value, setInputAmount);
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Tangal Pinjaman</Form.Label>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                maxDate={new Date()}
                onChange={(e: Date) => setInputDate(e)}
                selected={inputDate}
                customInput={<CustomDatePicker />}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Bunga Tahunan</Form.Label>
              <InputGroup className="mb-3">
                <FormControl
                  type="number"
                  autoComplete="off"
                  placeholder="Bunga Tahunan"
                  value={inputInterest.toString()}
                  onChange={(e) => {
                    parseInputNumber(e.target.value, setInputInterest);
                  }}
                />
                <InputGroup.Text>%</InputGroup.Text>
              </InputGroup>
            </Form.Group>

            <div className="d-grid gap-2 pb-2">
              <Button
                variant="primary"
                onClick={submitHandler}
                disabled={isButtonLoading}
              >
                {isButtonLoading ? "Loading..." : "Submit"}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NewLoanModal;
