// @ts-ignore
import * as React from "react";
import {forwardRef} from "react";
import {Form} from "react-bootstrap";

const CustomDatePicker = forwardRef<any, any>(
  ({value, onClick, onChange, placeHolder = "Pilih Tanggal"}, ref) => (
    <Form.Control
      type="text"
      placeholder={placeHolder}
      onClick={onClick}
      value={value}
      onChange={onChange}
    />
  )
);

export default CustomDatePicker;
