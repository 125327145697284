import { LoanModel, LoanModelStatus } from "../../models/loan";
import { Alert, Button, Table } from "react-bootstrap";
import { currencyFormatter } from "../../helpers/formatter";
import * as React from "react";
import * as moment from "moment";
import {
  interestCalculator,
  loanRemainingCalculator,
  totalPaidLoan,
} from "../../helpers/loanCalculator";

type PropTypes = {
  loan: LoanModel;
  handleViewLoan?: any;
  showButton?: boolean;
};

const LoanDetail: React.FC<PropTypes> = ({
  loan,
  handleViewLoan,
  showButton = true,
}) => {
  return (
    <Alert
      key={loan._id}
      variant={loan.status === LoanModelStatus.PAID ? "success" : "danger"}
    >
      <Table striped bordered hover>
        <tbody>
          <tr>
            <td width="50%">Peminjam</td>
            <td className="text-end">{loan.borrower}</td>
          </tr>
          <tr>
            <td width="50%">Keperluan</td>
            <td className="text-end">{loan.desc}</td>
          </tr>
          <tr>
            <td width="50%">Tanggal Pinjam</td>
            <td className="text-end">
              {moment(loan.loan_date).format("DD/MM/YYYY")}
            </td>
          </tr>
          <tr>
            <td width="50%">Jumlah Pinjaman</td>
            <td className="text-end">
              {currencyFormatter.format(loan.amount)}
            </td>
          </tr>

          {/*Show data based on loan status*/}
          {loan.status === LoanModelStatus.UNPAID ? (
            <>
              <tr>
                <td width="50%">Bunga({loan.yearly_interest * 100 || 15}%)</td>
                <td className="text-end">
                  {loan.status === LoanModelStatus.PAID
                    ? "-"
                    : currencyFormatter.format(interestCalculator(loan))}
                </td>
              </tr>
              <tr>
                <td width="50%">Sisa Pinjaman</td>
                <td className="text-end">
                  {currencyFormatter.format(loanRemainingCalculator(loan))}
                </td>
              </tr>
            </>
          ) : (
            <>
              <tr>
                <td width="50%">Total Pembayaran</td>
                <td className="text-end">
                  {currencyFormatter.format(totalPaidLoan(loan))}
                </td>
              </tr>
            </>
          )}
        </tbody>
      </Table>
      {showButton && (
        <div className="d-grid gap-2">
          <Button onClick={() => handleViewLoan(loan._id)}>Lihat</Button>
        </div>
      )}
    </Alert>
  );
};

export default LoanDetail;
