import { TokenModel, TokenModelSchema } from "../models/token";

const useAuth = () => {
  const TOKEN = "TOKEN";
  const token = localStorage.getItem(TOKEN);

  let returnData: [TokenModel, Error] = [null, null];

  if (token === null || token === "") {
    returnData[1] = new Error("Token not found");
    return returnData;
  }

  returnData[0] = JSON.parse(token);
  const res = TokenModelSchema.validate(returnData[0]);

  if (res.error) {
    returnData[1] = new Error(res.error.message);
  }

  return returnData;
};

export default useAuth;
