import useAuth from "../../hooks/auth";
import axios from "axios";

export const petrikorClient = (documentName: string) => {
  const DOMAIN = `https://db.daussho.my.id`;
  const [auth] = useAuth();

  const insertMany = (data: any) => {
    return axios({
      url: `${DOMAIN}/db/insert-many/${documentName}`,
      method: "POST",
      headers: {
        "x-api-key": auth.token,
      },
      data,
    });
  };

  const insert = (data: any) => {
    return axios({
      url: `${DOMAIN}/db/insert/${documentName}`,
      method: "POST",
      headers: {
        "x-api-key": auth.token,
      },
      data,
    });
  };

  const findBy = (data: any = {}) => {
    return axios({
      url: `${DOMAIN}/db/find-by/${documentName}`,
      method: "POST",
      headers: {
        "x-api-key": auth.token,
      },
      data,
    });
  };

  const updateById = (id: number, updatedData: any) => {
    return axios({
      url: `${DOMAIN}/db/update-by-id/${documentName}`,
      method: "PUT",
      headers: {
        "x-api-key": auth.token,
      },
      data: {
        id,
        data: updatedData,
      },
    });
  };

  return {
    insert,
    insertMany,
    findBy,
    updateById,
  };
};
