import { LoanModel, LoanModelStatus } from "../models/loan";
import * as moment from "moment";

export const interestCalculator = (data: LoanModel) => {
  const currenDate = moment();
  const loanDate = moment(data.loan_date);

  const yearlyInterestRate = data.yearly_interest || 0.15;
  const monthlyInterestRate = yearlyInterestRate / 12;

  const monthDiff = currenDate.diff(loanDate, "month");

  return data.amount * (monthlyInterestRate * monthDiff);
};

export const loanRemainingCalculator = (data: LoanModel) => {
  if (data.status === LoanModelStatus.PAID) return 0;

  const interest = interestCalculator(data);

  const paid = totalPaidLoan(data);

  return data.amount + interest - paid;
};

export const totalPaidLoan = (data: LoanModel) => {
  return data.transactions.reduce((accumulator, tx) => {
    return accumulator + tx.amount;
  }, 0);
};
