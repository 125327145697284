import { petrikorClient } from "../petrikor/client";
import { LoanModel } from "../../models/loan";
import hashCode from "../hash";

const appName = hashCode(process.env.GATSBY_LOAN_DB || "loan");
const loanDB = `${appName}-loan`;

const newLoan = (loanData: LoanModel) => {
  return petrikorClient(loanDB).insert(loanData);
};

const getLoan = async () => {
  const res = await petrikorClient(loanDB).findBy({
    order: {
      status: "asc",
    },
  });

  const loanList: LoanModel[] = res.data.data;

  return loanList;
};

const newPayment = (data: LoanModel) => {
  return petrikorClient(loanDB).updateById(data._id, {
    status: data.status,
    transactions: data.transactions,
  });
};

const LoanApi = {
  newLoan,
  getLoan,
  newPayment,
};

export default LoanApi;
