import * as React from "react";

export const currencyFormatter = new Intl.NumberFormat("id-ID", {
  style: "currency",
  currency: "IDR",
});

export const parseInputNumber = (
  input: string,
  changeState: React.Dispatch<React.SetStateAction<number>>
) => {
  const newNumber = parseInt(input);

  if (isNaN(newNumber)) {
    changeState(0);
    return;
  }

  changeState(newNumber);
};
