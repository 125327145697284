import * as React from "react";
import { useEffect, useState } from "react";
import { Button, Container, Spinner } from "react-bootstrap";
import LoanDetail from "../../../components/loan/loanDetail";
import { LoanModel } from "../../../models/loan";
import { AuthProps } from "../../../models/token";
import PageHelmet from "../../../components/common/helmet";
import LoanApi from "../../../helpers/api/loan";
import NewLoanModal from "../../../components/loan/modals/newLoan";
import LoanDetailModal from "../../../components/loan/modals/loanDetail";

const LoanPage: React.FC<AuthProps> = ({ auth }) => {
  const [show, setShow] = useState(false);
  const [showNewLoan, setShowNewLoan] = useState(false);
  const [loanDetail, setLoanDetail] = useState<LoanModel | null>(null);
  const [loanList, setLoanList] = useState<LoanModel[]>([]);
  const [loading, setLoading] = useState({ list: true });

  const handleViewLoan = (i: number) => {
    const loan = loanList.find((loan) => loan._id === i);

    if (!loan) return;

    setLoanDetail({ ...loan });
    setShow(true);
  };

  const fetchLoanList = async () => {
    const res = await LoanApi.getLoan();

    setLoanList(res);

    const _loading = { ...loading };
    _loading.list = false;
    setLoading(_loading);
  };

  useEffect(() => {
    fetchLoanList();
  }, []);

  return (
    <>
      <PageHelmet title="Pinjaman" />
      <Container className="pt-3">
        {/*Show new loan button*/}
        {auth.isAdmin && (
          <div className="d-grid gap-2 pb-2">
            <Button
              variant="outline-primary"
              onClick={() => setShowNewLoan(true)}
            >
              Pinjaman baru
            </Button>
          </div>
        )}

        {loading.list ? (
          <div className="text-center">
            <Spinner animation="grow" />
          </div>
        ) : (
          loanList.map((loan, i) => (
            <LoanDetail key={i} loan={loan} handleViewLoan={handleViewLoan} />
          ))
        )}

        {show && (
          <LoanDetailModal
            loanDetail={loanDetail}
            state={{
              show: {
                value: show,
                set: setShow,
              },
            }}
          />
        )}

        {showNewLoan && (
          <NewLoanModal
            state={{
              show: {
                value: showNewLoan,
                set: setShowNewLoan,
              },
            }}
          />
        )}
      </Container>
    </>
  );
};

export default LoanPage;
